import { Api } from "./API";

export const GetSubscriptions = (page: number, user_code: any) =>
  Api().get(`/subscriptions/subscriptionsList?page=${page}&user_code=${user_code}`);
export const GetSubscription = (_id: string) =>
  Api().get(`/getSubscription/${_id}`);
export const DeleteSubscriptions = (data: any) =>
  Api().delete(`/subscriptions/deleteSubscription/${JSON.parse(data.data).plan_code}`);
export const CreateSubscriptions = (data: any) =>
  Api().post("/subscriptions/addSubscription", data);
export const UpdateSubscriptions = (data: any) =>
  Api().post("/subscriptions/updateSubscription", data);

export const GetDashoardInsight = () => Api().get("/insights/getInsights");
export const DashboardSalesFilter = (
  userCode: any,
  selectedMenuString: any,
  selectedCategoryString: any,
  selectedItemString: any,
  selectedModifierString: any,
  selectedPaymentMode: any
) =>
  Api().get(
    `/dashboard/filter?user_code=${userCode}&menu_codes=${selectedMenuString}&category_ids=${selectedCategoryString}&modifiers_list_ids=${selectedItemString}&payment_mode=${selectedPaymentMode}`
  );

export const MailSend = (user_code: any, type: any) => Api().get(`/salesReport?user_code=${user_code}&type=${type}`)

export const GetVendors = (page: number) => Api().get(`/vendor/vendorsList?page=${page}`);
export const GetVendor = (_id: any) => Api().get(`/vendor/getVendor/${_id}`);
export const CreateVendors = (data: any) =>
  Api().post("/vendor/vendorRegistration", data);
export const UpdateVendors = (data: any, _id: any) =>
  Api().post(`/vendor/updateVendor/${_id}`, data);
export const DeleteVendors = (_id: any) =>
  Api().delete(`/vendor/deleteVendor/${_id}`);
export const StatusVendors = (data: any, _id: any) =>
  Api().post(`/vendor/updateVendor/${_id}`, data);
export const StatusLocation = (data: any, _id: any) => Api().post(`/locations/updateStatus/${_id}`, data)

export const GetAllUsers = (page: number) =>
  Api().get(`/kiosk-user/kiosk-user?page=${page}`);
export const CreateUsers = (data: any) =>
  Api().post(`/kiosk-user/kiosk-user`, data);
export const DeleteUsers = () => Api().delete(`/kiosk-user/kiosk-user`);
export const GetUser = (_id: any) => Api().get(`/kiosk-user/kiosk-user/${_id}`);
export const UpdateUsers = (data: any, _id: any) =>
  Api().post(`/kiosk-user/kiosk-user/${_id}`, data);
export const StatusUsers = (data: any, _id: any) =>
  Api().post(`/kiosk-user/kiosk-user/${_id}`, data);

export const Login = (data: any) => Api().post("/superAdminLogin", data);
export const ForgotPassword = (data: Object) => Api().patch('/forgotPassword', data)
export const ResetPassword = (data: Object) => Api().post('/resetPassword', data)
export const PublishSubscription = (data: Object) => Api().post('/subscriptions/subscriptionsPublish', data)


export const ChangePassword = (data: any) =>
  Api().post("/changePassword", data);
export const UpdateProfile = (data: any) =>
  Api().post("/superadmin/profileUpdate", data);
export const GetInsightsData = () => Api().get("/superadmin/getInsights");
export const GetSalesTableData = (query: any) =>
  Api().get(`/superadmin/getSales?${query}`);

export const GetVendorLocations = (userCode: any, vendorCodes: any) =>
  Api().get(
    `/superadmin/dropdown/locations?user_code=${userCode}&vendor_codes=${vendorCodes}`
  );
export const GetVendorDetails = () => Api().get("/superadmin/dropdown/vendors");

export const GetInsightsFilteredData = (
  location_ids: any,
  vendor_ids: any,
  start_date: any,
  end_date: any
) =>
  Api().get(
    `/superadmin/getInsights?location_ids=${location_ids}&vendor_ids=${vendor_ids}&start_date=${start_date}&end_date=${end_date}`
  );
