import React, { Fragment, useEffect, useState } from "react";
import { HeaderBar } from "../../components/HeaderBar";
import {
  Typography,
  TableSortLabel,
  Grid,
  Box,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CloseSVG, EditSVG, EditSquerSVG } from "../../assets/svg";
import {
  Table,
  useTheme,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { useStyles } from "../../muistyles/useStyles";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { visuallyHidden } from "@mui/utils";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import {
  setIsCreate,
  setIsShow,
  setVendorData,
  setIsActive,
  setVendorID,
} from "../../store/reducers/VendorReducer";
import { useNavigate } from "react-router-dom";
import { SearchSVG } from "../../assets/svg";
import { withStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PaginationItem from "@mui/material/PaginationItem";
import { Api } from "../../service/API";
import { MuiDialog } from "../MuiDialog";
import { VendorsGets } from "../../servicehandler/vendors";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const valueA = String(a[orderBy]).toLowerCase();
  const valueB = String(b[orderBy]).toLowerCase();
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: any = [
  {
    id: "name",
    disablePadding: true,
    label: "Vendor Name",
  },
  {
    id: "total_users",
    disablePadding: false,
    label: "Total Users",
  },
  {
    id: "locations",
    disablePadding: false,
    label: "Locations",
  },
  {
    id: "subscription",
    disablePadding: false,
    label: "Subscription",
  },
  {
    id: "revenue",
    disablePadding: false,
    label: "Revenue",
  },
  {
    id: "reach",
    disablePadding: false,
    label: "Reach",
  },
];

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(32, 28, 28, 0.08)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        borderColor: "rgba(32, 28, 28, 0.08)",
      },
      "&:hover fieldset": {
        border: "none",
        borderColor: "rgba(32, 28, 28, 0.08)",
      },
      "&.Mui-focused fieldset": {
        border: "none",
        borderColor: "rgba(32, 28, 28, 0.08)",
      },
    },
  },
})(TextField);

function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, controller } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.TableHeadTitle}
              active={controller.field === headCell.id}
              direction={controller.order === "asc" ? "asc" : "desc"}
              onClick={createSortHandler(headCell?.id)}
              IconComponent={(event) => (
                <ArrowDropDownRoundedIcon
                  {...event}
                  sx={{ color: "rgba(32, 28, 28, 0.32)" }}
                />
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={classes.TableHeadTitle}>Status</TableCell>
      </TableRow>
    </TableHead>
  );
}

const VendorTable = () => {
  const classes = useStyles();
  const { palette }: any = useTheme();
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<Order>("asc");
  const [filteredValues, setFilteredValues] = useState<any>([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
    field: "",
    order: "",
  });
  const [orderBy, setOrderBy] = useState<any>("username");
  const [search, setSearch] = useState("");
  const { VendorList, isShow, isCreate, isActive, AllVendorList, pagination, totalRecords } =
    useAppSelector((state) => state?.Vendor);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = VendorList.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(totalRecords / controller.rowsPerPage);

  const navigate = useNavigate();

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [dialogId, setDialogId]: any = useState(null);
  const [dialogSelectedStatus, setDialogSelectedStatus] = useState(null);

  const handleOpenConfirmationDialog = (id: any, selectedStatus: any) => {
    setDialogId(id);
    setDialogSelectedStatus(selectedStatus);
    setIsConfirmationDialogOpen(true);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    setController({
      ...controller,
      field: property,
      order:
        property !== controller.field
          ? "desc"
          : controller.order === "asc"
          ? "desc"
          : "asc",
      page: 1,
    });

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    let lowercasedValue = value.toLowerCase();
    let filteredList = VendorList.filter((vendor: any) => {
      const vendorEmailLowercased = vendor.email.toLowerCase();
      return (
        vendorEmailLowercased.includes(lowercasedValue) ||
        vendor.name.toLowerCase().includes(lowercasedValue)
      );
    });

    console.log(filteredList, "filteredList");

    // Update the filtered values with the newly calculated list
    setFilteredValues(filteredList);
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    setController({
      ...controller,
      page: value,
    });
  };

  // const visibleRows = React.useMemo(() => {
  //   // Calculate the index of the last and first records for the current page
  //   const indexOfLastRecord = currentPage * recordsPerPage;
  //   const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  //   // Get the current page records
  //   const currentRecords = VendorList.slice(
  //     indexOfFirstRecord,
  //     indexOfLastRecord
  //   );

  //   // If there's a search query, use filteredValues, otherwise use currentRecords
  //   const dataToDisplay = search?.length > 0 ? filteredValues : currentRecords;

  //   // Perform sorting on the data to display
  //   return stableSort(dataToDisplay, getComparator(order, orderBy));
  // }, [
  //   order,
  //   orderBy,
  //   VendorList,
  //   currentPage,
  //   recordsPerPage,
  //   search,
  //   filteredValues,
  // ]);

  const visibleRows = React.useMemo(() => {
    stableSort(
      filteredValues.length > 0 ? filteredValues : VendorList,
      getComparator(order, orderBy)
    );
    return filteredValues.length > 0 ? filteredValues : VendorList;
  }, [order, orderBy, VendorList, filteredValues, search]);

  const VendorActiveStatus = async (id: number, selectedStatus: any) => {
    const data = {
      status: selectedStatus,
    };
    console.log(data);
    try {
      const response = await Api().post(`/vendor/updateVendor/${id}`, data);
      console.log(response);
      // Handle the response if needed
    } catch (error) {
      console.error("Error updating vendor status:", error);
      // Handle the error if needed
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      await VendorActiveStatus(dialogId, dialogSelectedStatus);
      setIsConfirmationDialogOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Error deactivating account:", error);
    }
  };

  // useEffect(() => {
  //   setController({
  //     ...controller,
  //     page: 1,
  //   });
  // }, [VendorList.length]);

  useEffect(() => {
    VendorsGets(controller.page);
  }, [controller.page]);

  // console.log(VendorList,"VendorList")

  return (
    <>
      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "35%", lg: "35%", xl: "35%" },
        }}
      >
        <CustomTextField
          type="text"
          placeholder="Search..."
          variant="outlined"
          value={search}
          fullWidth
          onChange={(event) => handleSearch(event.target.value)}
          InputProps={{
            inputProps: {
              style: {
                backgroundColor: "transparent",
                padding: "8px 14px",
                /* width: 307, */ height: 30,
              },
            },
            style: {
              borderRadius: "8px",
              backgroundColor: "rgba(32, 28, 28, 0.08)",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchSVG />
              </InputAdornment>
            ),
            ...(search !== "" && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleSearch("")}
                    sx={{ borderRadius: "12px" }}
                  >
                    <CloseSVG color={"#201C1C"} />
                  </IconButton>
                </InputAdornment>
              ),
            }),
          }}
        />
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} size={"medium"}>
          <Fragment>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={VendorList?.length}
              controller={controller}
            />
            <TableBody>
              {search?.length > 0 && filteredValues?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography variant="body2">No Data Found</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows?.map((row: any, index:any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.UserBodyCell}
                          sx={{ width: "146px" }}
                        >
                          {row?.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.UserBodyCell}
                          sx={{ width: "92px" }}
                        >
                          {row.total_users}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.UserBodyCell}
                          sx={{ width: "auto", textTransform: "lowercase" }}
                        >
                          {row.locations}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.UserBodyCell}
                          sx={{ width: "130px" }}
                        >
                          {row?.subscription_name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.UserBodyCell}
                          sx={{ width: "130px" }}
                        >
                          {row.revenue}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.UserBodyCell}
                          sx={{ width: "130px" }}
                        >
                          {row.reach || "Super Admin"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "12px",
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            color={row?.status ? "primary" : "error"}
                            onClick={() => {
                              const newStatus = !row?.status ? 1 : 0;
                              // dispatch(setVendorData(row));
                              // dispatch(setIsActive({ visible: !isActive, id: row?.id }));
                              // VendorActiveStatus(row?.id, newStatus);
                              handleOpenConfirmationDialog(
                                row?.id,
                                row?.status ? 0 : 1
                              );
                            }}
                            sx={{
                              width: "80px",
                              borderRadius: "8px",
                              padding: "8px 16px",
                              backgroundColor: row?.status
                                ? palette?.success?.lighter
                                : palette?.error?.lighter,
                              color: row?.status
                                ? palette?.success?.main
                                : palette?.error?.main,
                              "&:hover": {
                                backgroundColor: row?.status
                                  ? palette?.success?.lighter
                                  : palette?.error?.lighter,
                              },
                            }}
                          >
                            {row?.status ? "Active" : "Inactive"}
                          </Button>

                          <Button
                            fullWidth
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              navigate(`/vendor/view/${row?.id}`);
                            }}
                            sx={{
                              borderRadius: "8px",
                              padding: "8px 16px",
                              width: "120px",
                            }}
                          >
                            Vendor View
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            color="inherit"
                            startIcon={<EditSVG />}
                            onClick={() => navigate(`/vendor/${row?.id}`)}
                            sx={{ borderRadius: "8px", padding: "8px 16px" }}
                          >
                            Edit
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Fragment>
          <MuiDialog
            open={isConfirmationDialogOpen}
            onClose={() => setIsConfirmationDialogOpen(false)}
            maxWidth={"xs"}
            sx={{ backgroundColor: "transparent" }}
          >
            <Box className={classes.DialogMain}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  lineHeight: "32px",
                }}
              >
                Are you sure?
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "center", lineHeight: "16px" }}
              >
                You want to change the status
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  width: "100%",
                }}
              >
                <Button
                  fullWidth
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleDeactivateAccount}
                >
                  Yes
                </Button>
                <Button
                  fullWidth
                  size="medium"
                  variant="contained"
                  color="error"
                  onClick={() => setIsConfirmationDialogOpen(false)}
                >
                  No
                </Button>
              </Box>
            </Box>
          </MuiDialog>
        </Table>
      </TableContainer>
      {/* {search?.length === 0 && VendorList.length > 9 - 1 && (
        <Pagination
          count={search ? Math.ceil(filteredValues.length / 10) : pagination}
          page={currentPage}
          color="primary"
          size="small"
          onChange={handlePaginationChange}
          className={classes.paginationPrevNextBtn}
        />
      )} */}

      {nPages > 1 && (
        <Pagination
          page={controller.page}
          count={nPages}
          color="primary"
          size="small"
          onChange={handlePaginationChange}
          className={classes.paginationPrevNextBtn}
        />
      )}
    </>
  );
};

export default VendorTable;
